.app__about {
  flex: 1;
  width: 90%;
  height: 100vh;
  flex-direction: column;

  @media screen and (min-width: 650px) {
    width: 90%;
    flex-direction: row;
  .app__profiles {
    height: 80vh;
    width: 90vw;
    align-items: center;
  }
  }
}

.app__profiles {
  display: flex;
  height: 70vh;
  overflow-y: scroll;
  width: auto;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
  
  @media screen and (min-width: 650px){
    height: 100vh;
    overflow-y: scroll;
  }
}

.app__profiles::-webkit-scrollbar {
  width: 2px;
}

.app__profiles::-webkit-scrollbar-track {
  background-color: var(--gray-3);
}

.app__profiles::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-color: var(--secondary-color);
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}