.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
  @media screen and (min-width: 650px) {
    flex: 1;
    height: 100vh;
    width: 90%;
    flex-direction: row-reverse;

    .app__skills-exp {
      height: 60vh;
      width: 20vw;
      overflow-y: scroll;
      margin-right: 1rem;
    }

    .app__skills-list{
      width: 40vw;
      margin-right: 1rem;
      height: 60vh;
      overflow-y: scroll;
    }
  }
}

.app__skills-container {
  width: 80%;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 900px) {
    width: 100%;
    flex-direction: column;
  }
}

.app__skills-list {
  // flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 50vh;
  overflow-y: scroll;
  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;

  }
}

.app__skills-list::-webkit-scrollbar {
  width: 2px;
}

.app__skills-list::-webkit-scrollbar-track {
  background-color: var(--gray-2);
}

.app__skills-list::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-color: var(--secondary-color);
}

.app__skills-item {
  flex-direction: column;
  text-align: center;
  position: relative;
  margin: .5rem;
  margin-bottom: 0;
  cursor: pointer;

  div{
    width: 90px;
    height: 90px;
  }

  #img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    transition: all 0.5s ease-in-out;

    .skill__percent {
      display: none;
    }

    img {
      width: 50%;
      height: 50%;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      box-shadow: 0 0 25px #fef4f5;
      background-color: #212529;

      img {
        display: none;
        transition: all 0.5s ease-in-out;
      }
      .skill__percent {
        display: block;
        color: var(--secondary-color);
        transition-property: all;
        transition: all .6s ease-in-out;
      }
    }
  }

  p {
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: 2000px) {
    margin: 1rem 2rem;

    p {
      margin-top: 1rem;
    }
  }

 
}

.app__skills-exp {
  // flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 50vh;
  overflow-y: scroll;
  

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp::-webkit-scrollbar {
  width: 2px;
}

.app__skills-exp::-webkit-scrollbar-track {
  background-color: var(--gray-2);
}

.app__skills-exp::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-color: var(--secondary-color);
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
  cursor: pointer;
}

.app__skills-exp-works {
  // flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }

  .skills-tooltip1 {
    height: 10px !important ;
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
  
    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }
}