.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    position: fixed;
    top: 0;
    z-index: 30;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
        width: 180px;
        height: 50px;

        @media screen and (min-width: 2000px) {
            width: 180px;
            height: 40px;
        }
    }

    @media screen and (min-width: 900px) {
        h1 {
            display: none;
        }
    }
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;

    li {
        margin: 0 1rem;
        cursor: pointer;
        flex-direction: column;

        div {
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
            transition: all 0.3s ease-in-out;
        }

        a {
            color: var(--white-color);
            text-decoration: none;
            flex-direction: column;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: var(--secondary-color);

            }
        }

        &:hover {
            div {
                background-color: var(--secondary-color);
            }
        }
    }

    @media screen and(max-width: 900px) {
        display: none;
    }
}

.app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondary-color);
    z-index: 30;

    svg {
        width: 70%;
        height: 70%;
        color: var(--white-color);
    }

    .menu-div {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 22;
        padding: 1rem;
        width: 80%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        background-color: var(--gray-2);
        box-shadow: 0 0 20px rgba(168, 168, 168, 0.233);

        .cross {
            width: 55px;
            height: 55px;
            color: var(--secondary-color);
        }

        .panel-image-nav {
            margin-top: 20%;
            border-radius: 50%;
            height: 160px;
            width: 160px;
            padding: 5px;
            background-color: var(--gray-2);
            display: flex;
            align-items: center;
            justify-content: center;
            align-self: center;

            img {
                height: 150px;
                width: 150px;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-self: center;
            flex-direction: column;

            li {
                margin: 1rem;

                a {
                    color: var(--white-color);
                    text-decoration: none;
                    font-size: 1rem;
                    text-transform: uppercase;
                    font-weight: 500;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        color: var(--secondary-color);
                    }
                }
            }
        }

        @media screen and(min-width: 650px) {
            width: 40%;
        }

        @media screen and(min-width: 900px) {
            display: none;
        }
    }

    @media screen and(min-width: 900px) {
        display: none;
    }
}