@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --primary-color: #edf2f8;
  --secondary-color: #28a745;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --gray-2: #212529;
  --gray-3: #343a40;
  --gray-4: #3d4349;
  --gray-text: #909294;
  --green-2: #28a745;

}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: #030303;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  width: 3px;
}

body::-webkit-scrollbar-track {
  background-color: var(--gray-3);
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 6px solid transparent;
  background-color: var(--secondary-color);
}