.app__footer {
  flex: 1;
  width: 90%;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 650px) {
    width: 100%;
    height: 100vh;
    flex-direction: column;
  }
}

.app__footer-cards {
  width: 60vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fef4f5;
    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }

    a {
      text-decoration: none;
      font-weight: 500;
    }

    &:hover {
      box-shadow: 0px 10px 13px -7px #000000, 5px 5px 7px 5px rgba(255, 255, 255, 0.08);
    }

    @media screen and (max-width: 450px) {
      width: 60%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 60%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #f2f7fb;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 7px 5px rgba(255,255,255,0.08);
}

.app__footer-form {
  width: 80%;
  flex-direction: column;
  margin: 0rem 2rem;

  div {
    width: 100%;

    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;

      background-color: var(--gray-2);
      color: var(--white-color);
      font-family: var(--font-base);
      outline: none;
    }

    textarea {
      height: 170px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0rem 0;
  }
}