.app {
  background-color: "#000000";
  font-family: var(--font-base);
  transition: 1s ease-in-out;
}

.app::-webkit-scrollbar {
  display: none;
}

.app__whitebg {
  background-color: var(--gray-2);
}

.app__primarybg {
  background-color: var(--gray-3);
}

.app__container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 4rem 2rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  padding: 2rem 0 0;
  z-index: 0;
  position: fixed;
  right: 2%;
  bottom: 3%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 21;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }

  @media screen and (max-width: 450px) {
    display: none;
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--white-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-text);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--white-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.app__social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  position: fixed;
  bottom: 1%;
  left: 1%;
  z-index: 5;

  padding: 1rem;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);
    line-height: 1.5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.footer__social {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;

  div {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: .5rem;
    margin-bottom: 0;
    border: 1px solid var(--lightGray-color);
    line-height: 1.5;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    transition: all 0.3s ease-in-out;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.footer__social-nav {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;

  div{
    margin: .5rem;
    margin-bottom: 0;

    svg {
      width: 15px;
      height: 15px;
      color: var(--gray-color);
      cursor: pointer;
    }
    &:hover {
      color: var(--secondary-color);
    }
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 40%;
  right: 1%;
  padding: 1rem;
  z-index: 20;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #cbcbcb;
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 650px) {
  .app__navigation {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .app__social {
    display: none;
  }

  .copyright {
    display: none;
  }
}

.copyright__sm {

  display: none;

  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-2);
    border: none;
    width: 100%;
    height: 100px;
  }
}

.section__info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .section__info-text {
    width: 15vw;
    color: var(--white-color);
    text-align: center;
    margin-top: 1.5rem;

  }

  @media screen and (max-width: 650px) {
    .section__info-text {
      width: 90%;
      height: auto;
      margin-bottom: 1.5rem;
    }
  }
}

.copyright__sm-nav {
  position: relative;
  background-color: transparent;
  align-self: center;
}

.button {
  padding: 1rem 2rem;
  border-radius: 10px;
  border: none;
  background-color: var(--white-color);

  font-weight: 500;
  color: var(--secondary-color);
  outline: none;
  margin: 2rem 0 0 0;
  font-family: var(--font-base);

  transition: all .5s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-color);
    color: var(--white-color);
  }
}