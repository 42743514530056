#home {
  position: relative;
  background: url('../../assets/cover-2.webp');

  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}



.side__panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
  background-color: rgba(52, 58, 64, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 30;

  .panel-image {
    margin-top: 20%;
    border-radius: 50%;
    height: 160px;
    width: 160px;
    padding: 5px;
    background-color: var(--gray-2);

    img {
      height: 150px;
      width: 150px;
    }
  }

  @media screen and (max-width: 1350px) {
    display: none;
  }
}

.right-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 30;

  .type-animation {
    margin: 1rem;
    color: var(--white-color);
    font-family: inherit;
    font-size: 1.75rem;
    text-align: center;
  }

  .arrow-animation {
    position: relative;
    top: 10%;
    margin-top: 1rem;
  }

  @media screen and (max-width: 1350px) {
    width: 100%;
  }

  @media screen and (max-width: 650px) {
    .type-animation {
      height: 100px;
      font-size: 1.5rem;
    }

    .arrow-animation {
      position: relative;
      top: 10%;
    }
  }
}